@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  position: absolute;
}

#nav {
  z-index: -1;
  background-color: #272829;
  height: fit-content;
}

.searchLabelText {
  font-size: 30px;
  font-weight: bold;
  color: #C9D2D7;
  text-shadow: 1px 1px 2px #000;
}
