body {
    font-family: 'Nunito Sans' !important;
}

.frontpage {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: cover;
    position: relative;
    top: 0px;
    left: -4px;
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
}

.reachOutContainer {
    position: relative;
    left: 1%;
    width: 98%;
    height: fit-content;
    margin: 0;
    padding: 0;
    background-color: #EDEDED;
    border: outset 5px #C9D2D7;
    border-radius: 25px;
    margin-bottom: 20px;
}

.reachOutContainer h1 {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    font-style: italic;
}

.reachOutContainer p {
    text-align: left;
    font-size: 30px;
}

.reachOutContainer h3 {
    font-size: 40px;
    text-align: center;
}

.image {
    position: relative;
    left: 1%;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}

.currentSlide {
    transition-duration: 0.5s ease;
    opacity: 0;
}

.currentSlide.active {
    opacity: 1;
    transition-duration: 0.5s;
    transform: scale(1.2);
}

.foot {
    margin: 0;
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    vertical-align: top;
    background-color: #272829;
    width: 100%;
    border-top: 3px outset #000;
}

.footdesc {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    left: 10%;
    width: 80%;
    align-items: center;
    vertical-align: bottom;
    text-align: left;
}

.footdesc h1 {
    font-size: 24px;
    padding-bottom: 0px;
    color: #C9D2D7;
    text-align: center;
}

.footdesc h2 {
    font-size: 20px;
    padding-top: 5px;
    color: white;
}

.upanddown {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.center {
    text-align: center;
}

.page {
    align-content: center;
    align-items: center;
    justify-content: center;
}

.middlebox {
    display: flex;
    position: relative;
    left: 2.5%;
    width: 95%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 40px;
    padding-top: 70px;
    padding-bottom: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 40px;
    border-color: #C9D2D7;
    border-style: outset;
    border-radius: 5px;
    background-color: #777777;
    opacity: 90%;
}

.middlebox img {
    background-color:white;
    width: 400px;
    height: 600px;
    opacity: 100%;
}

.yellow-border {
    padding: 20px 10px 20px 10px;
    border: outset 5px #C9D2D7;
    border-radius: 25px;
}

.middlebox button {
    color: #061C3D;
    font-size: xx-large;
}

#igIcon {
    background-image: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.searchPageTire {
    height: 300px
}
